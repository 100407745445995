import React from 'react'

const AppFooter = () => (
  <div className="app-footer">
    <div className="app-footer__inner">
      <div className="app-footer-left">
        <span>
          copyright © and development {new Date().getFullYear()}{' '}
          <a href="https://itecperu.com" target="_blank">
            Grupo itecPerú S.R.L.
          </a>
        </span>
      </div>
      <div className="app-footer-right">
        <span>
          Versión: <strong>2.0.320</strong>
        </span>
      </div>
    </div>
  </div>
)

export default AppFooter
