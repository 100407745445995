import gql from 'graphql-tag'

const Matricula = {
  create: (
    client,
    { curso_id, alumno_id, pago, descuento, fecha_pago, nuevo, voucher, descripcion, estado, drive },
    recursos
  ) => {
    return client.mutate({
      mutation: gql`mutation createMatricula(
                    $curso_id: Int!,
                    $alumno_id: Int!,
                    $pago: Float!,
                    $descuento: Float,
                    $fecha_pago: String!,
                    $nuevo: Boolean!,
                    $voucher: String!,
                    $descripcion: String!,
                    $estado: Boolean!,
                    $drive: String
                 ) {
                  createMatricula(
                    curso_id: $curso_id
                    alumno_id: $alumno_id,
                    pago: $pago,
                    descuento: $descuento,
                    fecha_pago: $fecha_pago,
                    nuevo: $nuevo,
                    voucher: $voucher,
                    descripcion: $descripcion,
                    estado: $estado,
                    drive: $drive
                    ) {
                    ${recursos}
                  }
                }`,
      variables: {
        curso_id: Number(curso_id),
        alumno_id: Number(alumno_id),
        pago: Number(pago),
        descuento: Number(descuento),
        fecha_pago,
        nuevo,
        voucher,
        descripcion,
        estado,
        drive
      }
    })
  },
  getAll: (client, recursos) => {
    return client.query({
      query: gql`{
              matriculas {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  getAllPendientes: (client, recursos) => {
    return client.query({
      query: gql`{
              matriculasPendientes {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  getAllByCursoId: (client, curso_id, recursos) => {
    return client.query({
      query: gql`query getMatriculasByCursoId($curso_id: Int!){
                matriculasByCursoId(curso_id: $curso_id) {
                    ${recursos}
                }
            }`,
      variables: {
        curso_id: Number(curso_id)
      },
      fetchPolicy: 'no-cache'
    })
  },
  matriculasByCursoIdGroup: (client, { curso_id, time }, recursos) => {
    return client.query({
      query: gql`query getMatriculasByCursoIdGroup($curso_id: Int!, $time: String!){
                matriculasByCursoIdGroup(curso_id: $curso_id, time: $time) {
                    ${recursos}
                }
            }`,
      variables: {
        curso_id: Number(curso_id),
        time
      },
      fetchPolicy: 'no-cache'
    })
  },
  matriculasByCursoIdGroupImporte: (client, { curso_id, time }, recursos) => {
    return client.query({
      query: gql`query getMatriculasByCursoIdGroupImporte($curso_id: Int!, $time: String!){
                matriculasByCursoIdGroupImporte(curso_id: $curso_id, time: $time) {
                    ${recursos}
                }
            }`,
      variables: {
        curso_id: Number(curso_id),
        time
      },
      fetchPolicy: 'no-cache'
    })
  },
  matriculasByCursosAlumnos: (client, recursos) => {
    return client.query({
      query: gql`{
              matriculasByCursosAlumnos {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  matriculasByCursosImportes: (client, recursos) => {
    return client.query({
      query: gql`{
              matriculasByCursosImporte {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  matriculasPendientes: (client, recursos) => {
    return client.query({
      query: gql`{
              matriculasPendientes {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  getById: (client, id, recursos) => {
    return client.query({
      query: gql`query getMatricula($id: Int!){
                matricula(id: $id) {
                    ${recursos}
                }
            }`,
      variables: {
        id: Number(id)
      },
      fetchPolicy: 'no-cache'
    })
  },
  matriculasByAlumnoIdEstado: (client, { alumno_id, is_activa }, recursos) => {
    return client.query({
      query: gql`query matriculasByAlumnoIdEstado($alumno_id: Int!, $is_activa: Boolean){
                matriculasByAlumnoIdEstado(alumno_id: $alumno_id, is_activa: $is_activa) {
                    ${recursos}
                }
            }`,
      variables: {
        alumno_id: Number(alumno_id),
        is_activa
      },
      fetchPolicy: 'no-cache'
    })
  },
  update: (
    client,
    { id, curso_id, alumno_id, pago, descuento, fecha_pago, nuevo, voucher, descripcion, estado, drive },
    recursos
  ) => {
    return client.mutate({
      mutation: gql`mutation updateMatricula($id: Int!, $update: JSON!){
                updateMatricula(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
      variables: {
        id: Number(id),
        update: {
          curso_id: Number(curso_id),
          alumno_id: Number(alumno_id),
          pago,
          descuento,
          fecha_pago,
          nuevo,
          voucher,
          descripcion,
          estado,
          drive
        }
      }
    })
  },
  delete: (client, id, recursos) => {
    return client.mutate({
      mutation: gql`mutation deleteMatricula($id: Int!){
                deleteMatricula(id: $id) {
                    ${recursos}
                  }
                }`,
      variables: {
        id: Number(id)
      }
    })
  },
  newPendiente: (client, recursos) => {
    return client.subscribe({
      query: gql`subscription {
        newPendiente {
            ${recursos}
          }
      }`
    })
  }
}

export default Matricula
