import gql from "graphql-tag"

const Auth = {
    login: (client, {
        email,
        password
    }, recursos) => {
        return client.query({
            query: gql`query createToken($email: String!, $password: String!, $social: JSON!){
              login(email: $email, password: $password, social: $social) {
                ${recursos}
              }
            }`,
            variables: {
                email,
                password,
                social: {
                    type: 'web'
                }
            },
            fetchPolicy: "no-cache"
        })
    },
    passwordReset: (client, {
        email,
        password,
        token
    }, recursos) => {
        return client.mutate({
            mutation: gql`mutation resetPassword($email: String!, $password: String!, $token: String!){
                  passwordReset(email: $email password: $password token: $token) {
                    ${recursos}
                  }
                }`,
            variables: {
                email,
                password,
                token
            }
        })
    },
    reset: (client, {
        email
    }, recursos) => {
        return client.query({
            query: gql`query resetPassword($email: String!){
                  reset(email: $email) {
                    ${recursos}
                  }
                }`,
            variables: {
                email
            },
            fetchPolicy: "no-cache"
        })
    },
    emailConfirmation: (client, {
        token
    }, recursos) => {
        return client.mutate({
            mutation: gql`mutation confirmationEmail($token: String!){
                      emailConfirmation(token: $token) {
                        ${recursos}
                      }
                    }`,
            variables: {
                token
            }
        })
    },
    verify: (client, {
        token
    }, recursos) => {
        return client.query({
            query: gql`query resetVerify($token: String!){
                  verify(token: $token) {
                    ${recursos}
                  }
                }`,
            variables: {
                token
            },
            fetchPolicy: "no-cache"
        })
    }
}

export default Auth