import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import MetisMenu from 'react-metismenu'

import { HomeNav, AppNav, ReportNav, AdminNav } from './NavItems'
import { useAuth } from '../../Context'
import { decodeToken } from '../../utils/scripts'

const Nav = () => {
  const { auth } = useAuth()
  const { authentication, permissions } = auth
  const { role } = decodeToken(authentication)

  return (
    <Fragment>
      <MetisMenu
        content={HomeNav}
        activeLinkFromLocation
        className="vertical-nav-menu mt-2 "
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      <MetisMenu
        content={AppNav}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      {role === 'Administrador' ? (
        <Fragment>
          <h5 className={`app-sidebar__heading`}>REPORTES</h5>
          <MetisMenu
            content={ReportNav}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
          <h5 className={`app-sidebar__heading`}>ADMINISTRADOR</h5>
          <MetisMenu
            content={AdminNav}
            activeLinkFromLocation
            className="vertical-nav-menu"
            iconNamePrefix=""
            classNameStateIcon="pe-7s-angle-down"
          />
        </Fragment>
      ) : (
        ''
      )}
    </Fragment>
  )
}

export default withRouter(Nav)
