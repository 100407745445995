import React, { useEffect } from 'react'
require('dotenv').config()
const loading = require('../../../assets/loading.gif')
export const Checkout = () => {
  useEffect(() => {
    const url = new URL(window.location.href)
    const referenceCode = url.searchParams.get('referenceCode')
    const amount = url.searchParams.get('amount')
    const currency = url.searchParams.get('currency')
    const buyerEmail = url.searchParams.get('buyerEmail')
    const sign = url.searchParams.get('sign')
    const $description = document.getElementById('description')
    const $referenceCode = document.getElementById('referenceCode')
    const $signature = document.getElementById('signature')
    const $currency = document.getElementById('currency')
    const $buyerEmail = document.getElementById('buyerEmail')
    const $amount = document.getElementById('amount')

    const $formPayu = document.getElementById('formPayu')
    const $divLoading = document.getElementById('div-loading')
    console.log(referenceCode, amount, currency, buyerEmail)
    if (referenceCode && amount && currency && buyerEmail) {
      // const sign = md5(`8F2c2g6pbSbzxou368MuxAP1Up~672878~${referenceCode}~${amount}~${currency}`) PRODUCTION
      // const sign = md5(`4Vj8eK4rloUd272L48hsrarnUA~508029~${referenceCode}~${amount}~${currency}`) //DEMO
      if (sign) {
        console.log('sign', sign)
        $description.value = `Arma tu PACK - ${referenceCode}`
        $referenceCode.value = referenceCode
        $signature.value = sign
        $currency.value = currency
        $buyerEmail.value = buyerEmail
        $amount.value = amount
        $formPayu.submit()
      } else {
        $divLoading.innerHTML = `
      <label>Error al procesar sus datos</label>
    `
      }
    } else {
      $divLoading.innerHTML = `
      <label>Error al procesar sus datos</label>
    `
    }
    return () => {}
  }, [])
  return (
    <div style={{ backgroundColor: '#0e1e2f', height: '100vh', display: 'flex' }}>
      <div style={{ backgroundColor: '#0e1e2f' }} id="div-loading">
        <img width={25} src={loading} />
      </div>
      <form id="formPayu" method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu">
        <input id="merchantId" name="merchantId" type="hidden" value="672878" />
        <input id="accountId" name="accountId" type="hidden" value="675550" />
        <input id="description" name="description" type="hidden" value="" />
        <input id="referenceCode" name="referenceCode" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input name="tax" type="hidden" value="0" />
        <input name="taxReturnBase" type="hidden" value="0" />
        <input id="currency" name="currency" type="hidden" value="" />
        <input id="signature" name="signature" type="hidden" value="" />
        <input id="buyerEmail" name="buyerEmail" type="hidden" value="" />
        <input name="responseUrl" type="hidden" value="http://app.aulacersa.com" />
        <input name="confirmationUrl" type="hidden" value="http://app.aulacersa.com" />
        <input style={{ display: 'none' }} name="Submit" type="submit" value="Enviar" />
      </form>
    </div>
  )
}
