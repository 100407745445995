import gql from 'graphql-tag'

const Pack = {
  create: (client, { payu_id, fecha_emision, moneda, observaciones, alumno_email }, recursos) => {
    return client.mutate({
      mutation: gql`mutation createPack(
                    $payu_id: String!,
                    $fecha_emision: String!,
                    $moneda: String!,
                    $observaciones: String!,
                    $alumno_email: String!,
                 ) {
                  createPack(
                    payu_id: $payu_id,
                    fecha_emision: $fecha_emision,
                    moneda: $moneda,
                    observaciones: $observaciones,
                    alumno_email: $alumno_email,
                    ) {
                    ${recursos}
                  }
                }`,
      variables: {
        payu_id,
        fecha_emision,
        moneda,
        observaciones,
        alumno_email
      }
    })
  },
  getAll: (client, recursos) => {
    return client.query({
      query: gql`{
              packs {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  getById: (client, id, recursos) => {
    return client.query({
      query: gql`query getPack($id: Int!){
                pack(id: $id) {
                    ${recursos}
                }
            }`,
      variables: {
        id: Number(id)
      },
      fetchPolicy: 'no-cache'
    })
  },
  getPackMatricular: (client, id, recursos) => {
    return client.query({
      query: gql`query getPackMatricular($id: Int!){
                packMatricular(id: $id) {
                    ${recursos}
                }
            }`,
      variables: {
        id: Number(id)
      },
      fetchPolicy: 'no-cache'
    })
  },
  getAllPendientes: (client, recursos) => {
    return client.query({
      query: gql`{
              packsPendientes {
                ${recursos}
              }
            }`,
      fetchPolicy: 'no-cache'
    })
  },
  update: (client, { id, payu_id, fecha_emision, moneda, observaciones, alumno_email }, recursos) => {
    return client.mutate({
      mutation: gql`mutation updatePack($id: Int!, $update: JSON!){
                updatePack(id: $id, update: $update) {
                    ${recursos}
                  }
                }`,
      variables: {
        id: Number(id),
        update: {
          payu_id,
          fecha_emision,
          moneda,
          observaciones,
          alumno_email
        }
      }
    })
  },
  delete: (client, id, recursos) => {
    return client.mutate({
      mutation: gql`mutation deletePack($id: Int!){
                deletePack(id: $id) {
                    ${recursos}
                  }
                }`,
      variables: {
        id: Number(id)
      }
    })
  },
  newPendiente: (client, recursos) => {
    return client.subscribe({
      query: gql`subscription {
        newPackPendiente {
            ${recursos}
          }
      }`
    })
  }
}

export default Pack
