module.exports = {
  HomeNav: [
    {
      icon: 'pe-7s-home',
      label: 'Inicio',
      to: '/home'
    }
  ],
  AppNav: [
    {
      icon: 'pe-7s-news-paper',
      label: 'Cursos',
      content: [
        {
          label: 'Nuevo Curso',
          to: '/app/curso/create'
        },
        {
          label: 'Lista de Cursos Activos',
          to: '/app/cursos-activos'
        },
        {
          label: 'Lista de Cursos',
          to: '/app/cursos'
        },
        {
          label: 'Categorías',
          to: '/app/categorias'
        },
        {
          label: 'Promociones',
          to: '/app/promociones'
        }
      ]
    },
    {
      icon: 'pe-7s-users',
      label: 'Docentes',
      content: [
        {
          label: 'Lista de Docentes',
          to: '/app/docentes'
        }
      ]
    },
    {
      icon: 'pe-7s-study',
      label: 'Alumnos',
      content: [
        {
          label: 'Nuevo Alumno',
          to: '/app/alumno/create'
        },
        {
          label: 'Lista de Alumnos',
          to: '/app/alumnos'
        }
      ]
    },
    {
      icon: 'pe-7s-bell',
      label: 'Notificaciones',
      to: '/app/notifications'
    },
    {
      icon: 'pe-7s-albums',
      label: 'Packs',
      to: '/app/packs'
    }
  ],
  ReportNav: [
    {
      icon: 'pe-7s-graph1',
      label: 'Ventas',
      to: '/report/ventas'
    }
  ],
  AdminNav: [
    {
      icon: 'pe-7s-users',
      label: 'Usuarios',
      to: '/admin/users'
    },
    {
      icon: 'pe-7s-door-lock',
      label: 'Roles',
      to: '/admin/roles'
    }
  ]
}
