import React, { Fragment } from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import HeaderLogo from '../AppLogo'
import Notificaciones from './Components/Notificaciones'
import UserBox from './Components/UserBox'

const Header = props => {
  let { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = props

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />

        <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
          <div className="app-header-right">
            <Notificaciones />
            <UserBox />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
