import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Loader } from 'react-loaders'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { useToasts } from 'react-toast-notifications'

import { AuthContext } from '../../Context'
import PrivateRoute from '../../PrivateRoute'
import { Checkout } from '../../Views/Auth/Payu/Checkout'
import Login from '../../Views/Auth/Login'
import Reset from '../../Views/Auth/Reset'
import Verify from '../../Views/Auth/Verify'
import Home from '../../Views/Home'
import useLocalStorage from '../../hooks/useLocalStorage'

const App = lazy(() => import('../../Views/App'))
const Report = lazy(() => import('../../Views/Report'))
const Admin = lazy(() => import('../../Views/Admin'))

require('dotenv').config()
let client

const AppMain = () => {
  const { addToast, removeAllToasts } = useToasts()

  const [auth, setAuth] = useLocalStorage('Auth', {})

  const setClient = auth => {
    setAuth(auth)

    const { authentication } = auth

    client = new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError, operation, forward }) => {
          if (networkError) {
            const { statusCode } = networkError

            switch (statusCode) {
              case 401:
                // User access token has expired
                // We assume we have both tokens needed to run the async request
                // Let's refresh token through async request
                return new Observable(observer => {
                  client
                    .query({
                      query: gql`
                        query refreshToken($authorization: String!) {
                          refresh(authorization: $authorization) {
                            authentication
                            name
                            email
                            permissions
                            services
                            layout
                          }
                        }
                      `,
                      variables: {
                        authorization: authentication
                      },
                      fetchPolicy: 'no-cache'
                    })
                    .then(response => {
                      console.log(response)
                      const { refresh } = response.data
                      if (response.error || refresh === null) {
                        setAuth({})
                        window.location.href = '/login'
                        return
                      }

                      operation.setContext({
                        headers: {
                          authorization: refresh.authentication
                        }
                      })

                      forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                      })

                      setClient(refresh)
                    })
                    .catch(error => {
                      // No refresh or client token available, we force user to login
                      observer.error(error)
                      setAuth({})
                      window.location.href = '/login'
                    })
                })
              case 403:
                addToast('No tiene los privilegios...', { appearance: 'error', autoDismissTimeout: 5000 })

                break
              default:
                break
            }
          }
        }),
        ApolloLink.split(
          ({ query }) => {
            const { kind, operation } = getMainDefinition(query)
            return kind === 'OperationDefinition' && operation === 'subscription'
          },
          new WebSocketLink({
            uri: `wss://${process.env.REACT_APP_WS}/graphql`,
            options: {
              reconnect: true,
              connectionParams: {
                headers: {
                  authorization: authentication
                }
              }
            }
          }),
          new HttpLink({
            uri: `${process.env.REACT_APP_API_CERSA}/api`,
            headers: {
              authorization: authentication
            }
          })
        )
      ]),
      cache: new InMemoryCache()
    })
  }

  useEffect(() => {
    setClient(auth)
  }, [])

  return client ? (
    <AuthContext.Provider value={{ auth, setAuth, client, setClient, addToast, removeAllToasts }}>
      <Route exact path="/login" component={Login} />
      <Route exact path="/password/reset" component={Reset} />
      <Route exact path="/password/reset/:token/:user" component={Reset} />
      <Route exact path="/account/verify/:token" component={Verify} />
      <Route exact path="/payu/pago" component={Checkout} />

      <PrivateRoute path="/home" component={Home} />

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Cargando los Componentes...</h6>
            </div>
          </div>
        }
      >
        <Route path="/app" component={App} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Cargando los Componentes...</h6>
            </div>
          </div>
        }
      >
        <Route path="/report" component={Report} />
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">Cargando los Componentes...</h6>
            </div>
          </div>
        }
      >
        <Route path="/admin" component={Admin} />
      </Suspense>

      <Route exact path="/" render={() => <Redirect to={'/login'} />} />
    </AuthContext.Provider>
  ) : (
    <></>
  )
}

export default AppMain
