import React, { useState } from 'react'
import { Input } from 'reactstrap'

export default function useInput({ typeState, placeholder, initialState, maxLength }) {
  const [value, setValue] = useState(initialState ? initialState : '')
  const [invalid, setInvalid] = useState(false)
  const [type, setType] = useState(typeState ? typeState : 'text')

  const inputValue = (
    <Input
      value={value}
      onChange={({ target }) => setValue(target.value)}
      type={type}
      invalid={invalid}
      placeholder={placeholder}
      maxLength={maxLength ? maxLength : 255}
      bsSize="sm"
    />
  )

  return [value, inputValue, setValue, setInvalid, setType]
}
