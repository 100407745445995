import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Slider from 'react-slick'
import { Col, Row, Form } from 'reactstrap'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda'

import bg1 from '../../assets/utils/images/originals/bg1.jpg'
import bg2 from '../../assets/utils/images/originals/bg2.jpg'
import bg3 from '../../assets/utils/images/originals/bg3.jpg'

import { useAuth } from '../../Context'
import useInput from '../../hooks/useInput'
import MyLabel from '../../Components/MyLabel'
import Auth from '../../Components/Models/Auth'

const Login = () => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true
  }

  const { auth, client, setClient, addToast } = useAuth()

  const [email, inputEmail, setImail] = useInput({ typeState: 'email', placeholder: 'Email' })
  const [password, inputPassword, setPassword] = useInput({ typeState: 'password', placeholder: 'Contraseña' })

  const [expRight, setExpRight] = useState(false)

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') postLogin()
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [email, password])

  const postLogin = () => {
    setExpRight(true)

    Auth.login(client, { email, password }, 'id, authentication, nombres, email, permissions')
      .then(response => {
        const { login } = response.data

        setExpRight(false)

        if (login === null) {
          addToast('Credenciales incorrectas', { appearance: 'error', autoDismissTimeout: 5000 })
          return
        }

        const { authentication } = login

        if (authentication === 'verify') {
          addToast('Aún no ha verificado su email!', { appearance: 'error', autoDismissTimeout: 5000 })
          return
        }

        setClient(login)
      })
      .catch(({ message }) => {
        addToast(message, { appearance: 'error', autoDismissTimeout: 5000 })
        setExpRight(false)
      })
  }

  const handleSocialLogin = user => {
    console.log(user)
  }

  const handleSocialLoginFailure = err => {
    console.error(err)
  }

  return auth.authentication ? (
    <Redirect to={'/home'} />
  ) : (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="8" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg1 + ')'
                    }}
                  />
                  <div className="slider-content">
                    <h3>Sistema para la gestión de Cursos </h3>
                    <p>Se administrará los cursos del Instituto Cersa.</p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div
                    className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg3 + ')'
                    }}
                  />
                  <div className="slider-content">
                    <h3>Automatizamos</h3>
                    <p>procesos de matricula, cursos y alumnado</p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                  <div
                    className="slide-img-bg opacity-6"
                    style={{
                      backgroundImage: 'url(' + bg2 + ')'
                    }}
                  />
                  <div className="slider-content">
                    <h3>Administramos</h3>
                    <p>Matriculas, alumnos, cursos.</p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col lg="4" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <div className="text-center">
                <img src="/logo.png" alt="Logo" />
                <Row className="divider" />
                <h4 className="mb-0">
                  <div>Bienvenido,</div>
                  <span>Inicia sesión en tu cuenta.</span>
                </h4>
              </div>
              <Row className="divider" />
              <Form>
                <Row form>
                  <Col md={12} className="mb-2">
                    <MyLabel name="Email" />
                    {inputEmail}
                  </Col>
                  <Col md={12}>
                    <MyLabel name="Contraseña" />
                    {inputPassword}
                  </Col>
                </Row>
              </Form>
              <Row className="divider" />
              <div className="d-flex align-items-center mb-2">
                <div className="ml-auto">
                  <Link to="/password/reset" className="btn-lg btn btn-link">
                    ¿Olvidó su contraseña?
                  </Link>
                  <LaddaButton
                    className="btn btn-pill btn-primary"
                    loading={expRight}
                    onClick={postLogin}
                    data-style={EXPAND_RIGHT}
                  >
                    <FontAwesomeIcon icon={faUser} /> Ingresar
                  </LaddaButton>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default Login
