import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardHeader, Col, Row, CardTitle, CardBody } from 'reactstrap'
import { HorizontalBar } from 'react-chartjs-2'
import { faChartBar, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Layout
import AppHeader from '../Layout/AppHeader/'
import AppSidebar from '../Layout/AppSidebar/'
import AppFooter from '../Layout/AppFooter/'
// Theme Options
// import ThemeOptions from '../Layout/ThemeOptions/'

import { useAuth } from '../Context'
import Matricula from '../Components/Models/Matricula'

require('dotenv').config()

const Home = () => {
  const { client, addToast, removeAllToasts } = useAuth()
  const [alumnos, setAlumnos] = useState({
    labels: [],
    datasets: [
      {
        label: 'Alumnos',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,1)',
        hoverBorderColor: 'rgba(220,220,220,1)',
        borderCapStyle: 'round',
        data: []
      }
    ]
  })
  const [importes, setImportes] = useState({
    labels: [],
    datasets: [
      {
        label: 'Importes',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,1)',
        hoverBorderColor: 'rgba(220,220,220,1)',
        borderCapStyle: 'round',
        data: []
      }
    ]
  })

  useEffect(() => {
    addToast('Procesando...', { appearance: 'success', autoDismiss: false })

    Matricula.matriculasByCursosAlumnos(client, `alumnos, curso {id, nombre_corto}`)
      .then(response => {
        const { matriculasByCursosAlumnos } = response.data
        const labels = [],
          data = []
        matriculasByCursosAlumnos.forEach(({ curso, alumnos }) => {
          labels.push(`${curso.nombre_corto.toString().substring(0, 50)}...`)
          data.push(alumnos)
        })

        setAlumnos({
          ...alumnos,
          labels,
          datasets: [
            {
              ...alumnos.datasets[0],
              data
            }
          ]
        })
        removeAllToasts()
      })
      .catch(({ message }) => {
        removeAllToasts()
        addToast(message, { appearance: 'error', autoDismissTimeout: 5000 })
      })

    Matricula.matriculasByCursosImportes(client, `importe, curso {id, nombre_corto}`)
      .then(response => {
        const { matriculasByCursosImporte } = response.data
        const labels = [],
          data = []
        matriculasByCursosImporte.forEach(({ curso, importe }) => {
          labels.push(`${curso.nombre_corto.toString().substring(0, 50)}...`)
          data.push(importe)
        })

        setImportes({
          ...importes,
          labels,
          datasets: [
            {
              ...importes.datasets[0],
              data
            }
          ]
        })
        removeAllToasts()
      })
      .catch(({ message }) => {
        removeAllToasts()
        addToast(message, { appearance: 'error', autoDismissTimeout: 5000 })
      })
  }, [])

  useEffect(() => {}, [])
  return (
    <Fragment>
      {/*<ThemeOptions />*/}
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Card className="main-card">
              <CardHeader className="card-header-tab z-index-6">
                <FontAwesomeIcon icon={faChartLine} className="text-warning" /> REPORTES
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <CardTitle>
                      <FontAwesomeIcon icon={faChartBar} className="text-warning" /> Cursos con mayor cantidad de
                      Alumnos
                    </CardTitle>
                    <HorizontalBar data={alumnos} />
                  </Col>
                  <Col md="12">
                    <CardTitle>
                      <FontAwesomeIcon icon={faChartBar} className="text-warning" /> Cursos con mayor Ingresos
                    </CardTitle>
                    <HorizontalBar data={importes} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <AppFooter />
        </div>
      </div>
    </Fragment>
  )
}

export default Home
