import React, { Fragment } from 'react'
import { DropdownToggle, DropdownMenu, Nav, Button, NavItem, UncontrolledButtonDropdown } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faPowerOff, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import avatar from '../../../assets/utils/images/avatars/avatar.png'
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg'
import { useAuth } from '../../../Context'

const UserBox = () => {
  const { auth, setAuth } = useAuth()

  const logOut = () => {
    // delete auth.authentication
    setAuth({})
    window.location.href = '/login'
  }

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img width={42} className="rounded-circle" src={avatar} alt="Avatar" />
                  <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div
                        className="menu-header-image opacity-1"
                        style={{
                          backgroundImage: 'url(' + city3 + ')'
                        }}
                      />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img width={42} className="rounded-circle" src={avatar} alt="Avatar" />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">{auth.nombres}</div>
                              <div className="widget-subheading opacity-9">{auth.email}</div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button className="btn-pill btn-shadow btn-shine" color="danger" onClick={logOut}>
                                <FontAwesomeIcon icon={faPowerOff} /> Salir
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Nav vertical>
                    <NavItem className="nav-item-btn text-center">
                      <Button className="btn-pill btn-shadow btn-shine" color="info">
                        <Link to={`/admin/user/${auth.id}`} style={{ color: '#fff' }}>
                          <FontAwesomeIcon icon={faUserCircle} /> Mi Perfil
                        </Link>
                      </Button>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading">{auth.nombres}</div>
              <div className="widget-subheading opacity-9">{auth.email}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UserBox
