import moment from 'moment'
import jwt from 'jsonwebtoken'

require('dotenv').config()

export const date = () => moment().format('YYYY-MM-DD')
export const dateTime = dateTime =>
  moment(dateTime)
    .utcOffset('-0500')
    .format('YYYY-MM-DD HH:mm:ss')
export const dateTimeT = dateTimeValue =>
  dateTime(dateTimeValue)
    .replace(' ', 'T')
    .substring(0, 16)
export const dateTimeUTC = dateTimeValue => (dateTimeValue ? dateTimeValue.replace('T', ' ').substring(0, 19) : '')
export const dateTimeUTC16 = dateTimeValue => dateTimeValue.substring(0, 19)
export const uploadImg = (file, type, { authentication }, name = null) => {
  const formData = new FormData()
  formData.append('img', file)
  formData.append('type', type)
  formData.append('name', name ? name.toString().split('.')[0] : null)

  return fetch(`${process.env.REACT_APP_API_CERSA}/upload`, {
    method: 'POST',
    headers: {
      authorization: authentication
    },
    body: formData
  })
}
export const downloadPdf = async (url, file, type, { authentication }) => {
  return fetch(`${process.env.REACT_APP_API_CERSA}/${url}?file=${file}`, {
    method: 'GET',
    headers: {
      authorization: authentication
    }
  })
}
export const myToFixed = (number, decimals = 2) => {
  const rounding = Math.round(parseFloat(number) * Math.pow(10, decimals)) / Math.pow(10, decimals)

  return Number(rounding).toFixed(decimals)
}
export const decodeToken = authorization => jwt.decode(authorization)
